'use strict';

module.exports = function initProductImages() {
	const px_from_top = 170
	const $copy = document.querySelector('.cProduct .eCopy')

	if ($copy) {
		/*
		const $lightbox = document.getElementById('iPopupModal')

		$lightbox.addEventListener('click', function(ev) {
			$lightbox.classList.remove('show')
		})

		$lightbox.querySelector('.eClose').addEventListener('click', function(ev) {
			$lightbox.classList.remove('show')
		})

		Array.from(document.querySelectorAll('.cProduct .eImages a')).forEach(function($el) {
			$el.addEventListener('click', function(ev) {
				ev.preventDefault()

				$lightbox.querySelector('img').setAttribute('src', $el.href)
				$lightbox.classList.add('show')
			})
		})*/

		var view_height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
		var top = (view_height - ($copy.offsetHeight + 25))
		if (top < px_from_top) {
			$copy.style.top = top + 'px'
		}

		if ( $(window).width() >= 768 ) {
			productImagesDesktop();
		} else {
			productImagesMobile();
		}

		$(window).resize(function() {
			var view_height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
			var top = (view_height - ($copy.offsetHeight + 25))

			if (top < px_from_top) {
				$copy.style.top = top + 'px'
			}

			if ( $(window).width() >= 768 ) {
				productImagesDesktop();
			} else {
				productImagesMobile();
			}
		});

		function productImagesDesktop() {
			$('.cProduct .eImages').trigger('destroy.owl.carousel');
		}

		function productImagesMobile() {
			$('.cProduct .eImages').owlCarousel({
				loop: true,
				autoplay: true,
				margin: 30,
				animateOut: 'fadeOut',
				animateIn: 'fadeIn',
				nav: false,
				dots: false,
				autoplayHoverPause: false,
				items: 1,
			});
		}
	}
}