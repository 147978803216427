'use strict';
const React = require('react')
const ReactDomClient = require('react-dom/client')

module.exports = class Collection extends React.Component {
	constructor(props) {
		super(props)

		// cart_inital object pulled from a script tag under the div
		this.state = collection_initial
		this.state.loading = false
		this.state.quick_view = false
		this.state.quick_view_title = ''
		this.state.quick_view_url = 0
		this.state.quick_view_show = false

		history.replaceState({
			collection: this.state
		}, '')

		this.loadMore = this.loadMore.bind(this)
		this.quickView = this.quickView.bind(this)
		this.closeQuickView = this.closeQuickView.bind(this)
		this.showQuickView = this.showQuickView.bind(this)
		this.popState = this.popState.bind(this)
		window.addEventListener('popstate', this.popState)
	}
	popState(event) {
		if (event.state.collection) {
			event.state.collection.loading = false
			this.setState(event.state.collection)
		}
	}
	loadMore() {
		this.setState({loading: true})
		if (!this.state.loading) {
			let data = JSON.parse(JSON.stringify(this.state.filter_data))
			let category_id = 0
			Object.keys(data).forEach((key) => {
				if (!data[key] || data[key].length === 0) {
					delete data[key]
				} else if (key == 'category_id') {
					category_id = data[key]
					delete data[key]
				}
			})
			data['page']++

			let pathname = window.location.pathname

			let newUrl = pathname + '?' + new URLSearchParams(data)

			if (category_id != 0) {
				newUrl = newUrl + '&path=' + category_id
			}

			if (pathname == '/index.php') {
				newUrl = newUrl + '&route=product/category'
			}

			data['category_id'] = category_id
			data['route'] = 'extension/theme/walker/category_json'

			fetch('/index.php?' + new URLSearchParams(data)).then((response) => {
				if (!response.ok) {
					console.log(response)
				}

				return response.json()
			}).then((body) => {
				this.setState((current) => {
					body.products.forEach((item) => {
						current.products.push(item)
					})
					current.filter_data.page = body.filter_data.page
					current.loading = false

					history.pushState({
						collection: current
					}, '', newUrl)

					return current
				})
			})
		}
	}
	quickView(id, title) {
		if (this.state.quick_view_url != id) {
			this.setState({
				quick_view: true,
				quick_view_title: title,
				quick_view_url: id,
				quick_view_show: false,
			})
		} else {
			this.setState({
				quick_view: true,
				quick_view_show: true,
			})
		}
		document.querySelector(':root').classList.add('no-scroll')
	}
	showQuickView() {
		this.setState({
			quick_view_show: true,
		})
	}
	closeQuickView() {
		this.setState({
			quick_view: false,
			quick_view_show: false,
		})
		document.querySelector(':root').classList.remove('no-scroll')
	}
	render() {
		return (<React.Fragment>
			{this.state.products.map((product) => (
				<div className="col-6 col-lg-4 col-xxl-3 cProductCard" key={ product.product_id }>
					<div className="dezzy-product-thumb" onClick={(e) => { if (e.target.tagName.toLowerCase() != 'button') { e.preventDefault(); console.log(product.href); window.location = product.href }}}>
						<div className="image">
							<a href={ product.href }>
								<img src={ product.thumb } alt={ product.name } title={ product.name } className="img-fluid"/>
								<div className="actions gap-2">
									<button type="button" className="button-like" onClick={(e) => {e.preventDefault();wishlist.add(product.product_id)}}></button>
								</div>
							</a>
						</div>

						<div className="row">
							<div className="col-12 col-md-6 product-name">
								<a href={ product.href }>{ product.name }</a>
								<div className="cColours">
								{product.colours.map((colour) => (
									<img key={colour.product_option_value_id} src={ '/image/' + colour.image } alt={colour.name} title={colour.name} />
								))}
								</div>
							</div>
							<div className="col-12 col-md-6 price-info">
								{ !product.special ? (
									<React.Fragment>
										<h3>From</h3>
										<h1>{ product.price }</h1>
									</React.Fragment>
								) : (
									<React.Fragment>
										<h3>Was</h3>
										<span>{ product.price }</span>
										<h3 style={{marginTop: '5px'}}>Now</h3>
										<h1>{ product.special }</h1>
									</React.Fragment>
								)}
							</div>
						</div>
					</div>
				</div>
			))}
			<div className="fullwidth" style={{width:'100%'}}>
				<div className="col" style={{textAlign: 'center'}}>
					{(this.state.products.length == this.state.total ? (
						''
					) : ( 
						<button type="button" className="cButton mOrange mLarge" onClick={this.loadMore} disabled={this.state.loading ? true : undefined}><span>
							<span className={(this.state.loading ? 'spinner-border spinner-border-sm' : 'visually-hidden')} role="status" aria-hidden="true"></span>
							<span className={(this.state.loading ? '' : 'visually-hidden')}> &nbsp; Loading</span>
							<span className={(!this.state.loading ? '' : 'visually-hidden')}>Load More</span>
						</span></button>
					))}
				</div>
			</div>
		</React.Fragment>);
	}
}
/*
			<div className={(this.state.quick_view ? 'modal-backdrop fade show' : 'modal-backdrop fade')} onClick={this.closeQuickView}></div>
			<div className={(this.state.quick_view ? 'modal mQuickView fade show' : 'modal mQuickView fade')} tabIndex="-1" aria-labelledby="quick-view-label" role={(this.state.quick_view ? 'dialog' : undefined)}  aria-modal={(this.state.quick_view ? 'true' : undefined)} aria-hidden={(!this.state.quick_view ? 'true' : undefined)}>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className={(this.state.quick_view_show ? 'modal-header' : 'visually-hidden')}>
							<h5 className="modal-title" id="quick-view-label">{this.state.quick_view_title}</h5>
							<button type="button" className="btn-close" aria-label="Close" onClick={this.closeQuickView}></button>
						</div>
						<div className={(this.state.quick_view_show ? 'modal-body' : 'visually-hidden')}><iframe src={(this.state.quick_view_url == 0 ? undefined : '/index.php?route=extension/walker/product/quick&product_id='+this.state.quick_view_url)} onLoad={this.showQuickView}></iframe></div>
						<div className={(!this.state.quick_view_show ? 'modal-body eLoading' : 'visually-hidden')}>
							<span className="spinner-border" role="status" aria-hidden="true"></span>
							<span> &nbsp; Loading</span>
						</div>
					</div>
				</div>
			</div>
*/