'use strict';

// Third party
const owl = require('owl.carousel')
const React = require('react')
const ReactDomClient = require('react-dom/client')

import Alpine from 'alpinejs'
window.Alpine = Alpine

const Cart = require('./cart')
const Collection = require('./collection')
const initHeader = require('./header')
const initProductImages = require('./product.images')
//const initCarousels = require('./carousels')

// Array from polyfil
if (typeof Array.from == 'undefined') {
	Array.from = function (object) {
		return Array.slice.call(object)
	}
}

function initAll() {
	console.log('Loaded')
	initHeader()
	initProductImages()
	window.Alpine.start()

	$('.cCategoryCarousel .eImages').owlCarousel({
		loop: true,
		autoplay: true,
		margin: 30,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		nav: false,
		dots: false,
		autoplayHoverPause: false,
		items: 3,
		responsive: {
			0: {
				items: 1,
			},
			450: {
				items: 2,
			},
			991: {
				items: 3,
			}
		},
	});

	const $cart = document.getElementById('react-cart')
	const $collection = document.getElementById('product-list')
	const $order = document.getElementById('react-order')

	if ($cart) {
		const cartRoot = ReactDomClient.createRoot($cart)
		cartRoot.render(<Cart />)
	}

	if ($collection) {
		const collectionRoot = ReactDomClient.createRoot($collection)
		collectionRoot.render(<Collection />)
	}



	const Opencart = require('./common')
	// delay bootstrap tool tip a frame so react can finish initialisation then we can hook its tooltips
	setTimeout(() => {
		//window.jQuery('[data-bs-toggle="tooltip"]').tooltip()
	}, 100)
}

if (document.readyState !== 'loading') {
	initAll()
} else {
	document.addEventListener('DOMContentLoaded', initAll)
}