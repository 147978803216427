'use strict';

module.exports = function initHeader() {
	document.querySelector('.cHeader .eIcon.mMobile').addEventListener('click', function(ev) {
		document.documentElement.classList.toggle('mMobileNavigation')

		if (Array.from(document.documentElement.classList).indexOf('mMobileNavigation') == 1) {
			document.querySelector('.cHeader .eIcon.mMobile i').classList.remove('fa-bars')
			document.querySelector('.cHeader .eIcon.mMobile i').classList.add('fa-times')
		} else {
			document.querySelector('.cHeader .eIcon.mMobile i').classList.remove('fa-times')
			document.querySelector('.cHeader .eIcon.mMobile i').classList.add('fa-bars')
		}

		console.log(Array.from(document.documentElement.classList).indexOf('mMobileNavigation'))
	})

	Array.from(document.querySelectorAll('[data-navigation]')).forEach(function(el) {
		el.addEventListener('click', function(ev) {
			ev.preventDefault()

			Array.from(document.querySelectorAll('.cHeader .eNavigation li.mActive')).forEach(function(sub) {
				sub.classList.remove('mActive')
			})

			Array.from(document.querySelectorAll('.cHeader .eNavigation.mSubmenu.mShow')).forEach(function(sub) {
				sub.classList.remove('mShow')
			})

			el.classList.add('mActive')
			document.getElementById(el.getAttribute('data-navigation')).classList.add('mShow')
		})
	})
}