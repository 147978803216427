'use strict';
const React = require('react')
const ReactDomClient = require('react-dom/client')

window.ReactCart = {}

module.exports = class Cart extends React.Component {
	constructor(props) {
		super(props)

		// cart_inital object pulled from a script tag under the div
		//this.state = cart_inital

		this.state = {
			loading: true,
			open: false,
			products: [
			],
			totals: [
				{
					title: 'Sub-Total',
					text: '€0.00',
				},
				{
					title: 'Total',
					text: '€0.00',
				},
			],
		}

		window.ReactCart.updateCart = this.updateCart = this.updateCart.bind(this)
		window.ReactCart.toggleCart = this.toggleCart = this.toggleCart.bind(this)
		window.ReactCart.removeProduct = this.removeProduct = this.removeProduct.bind(this)
		window.ReactCart.addProduct = this.addProduct = this.addProduct.bind(this)
	}
	componentDidMount() {
		fetch('/index.php?route=extension/theme/walker/cart').then((response) => {
			if (!response.ok) {
				console.log(response)
			}

			return response.json()
		}).then((body) => {
			this.setState({
				open: false,
				loading: false,
				products: body.products,
				vouchers: body.vouchers,
				totals: body.totals
			})
		})
	}
	updateCart() {
		this.setState({loading: true})
		fetch('/index.php?route=extension/theme/walker/cart').then((response) => {
			if (!response.ok) {
				console.log(response)
			}

			return response.json()
		}).then((body) => {
			this.setState({
				open: true,
				loading: false,
				products: body.products,
				vouchers: body.vouchers,
				totals: body.totals
			})
		})
	}
	addProduct(product_id, quantity, options) {
		this.setState({loading: true})

		const body = new FormData()
		body.append('product_id', product_id)
		body.append('quantity', quantity)

		fetch('/index.php?route=checkout/cart|add', {
			method: 'POST',
			body,
		}).then((response) => {
			if (!response.ok) {
				console.log(response)
			}

			return response.json()
		}).then((body) => {
			if (body['success']) {
				document.querySelector('#alert').insertAdjacentHTML('afterbegin', '<div class="alert alert-success alert-dismissible"><i class="fas fa-exclamation-circle"></i> ' + body['success'] + ' <button type="button" class="btn-close" data-bs-dismiss="alert"></button></div>');
			}

			this.updateCart()
		})
	}
	removeProduct(cart_id) {
		this.setState({loading: true})

		const body = new FormData()
		body.append('key', cart_id)

		fetch('/index.php?route=checkout/cart/remove', {
			method: 'POST',
			body,
		}).then((response) => {
			if (!response.ok) {
				console.log(response)
			}

			return response.json()
		}).then((body) => {
			/*if (body['success']) {
				document.querySelector('#alert').insertAdjacentHTML('afterbegin', '<div class="alert alert-success alert-dismissible"><i class="fas fa-exclamation-circle"></i> ' + body['success'] + ' <button type="button" class="btn-close" data-bs-dismiss="alert"></button></div>');
			}*/

			this.updateCart()
		})
	}
	toggleCart() {
		this.setState({
			open: !this.state.open,
		});
	}
	render() {
		return (
			<div className="dropdown eCart">
				<button className="eIcon mCart" type="button" id="iCartOpenButton" data-toggle="dropdown" aria-expanded="false" onClick={this.toggleCart} disabled={this.state.loading ? true : undefined}>
					<i className={(this.state.loading ? 'far fa-spinner fa-pulse' : 'far fa-shopping-cart')}></i>
					{this.state.products.length == 0 ? '' : (<div className="eIndicator">{this.state.products.reduce((result, product) => (result+parseInt(product.quantity)), 0)}</div>)}
				</button>
				<div className={this.state.open?'dropdown-menu dropdown-menu-right show':'dropdown-menu dropdown-menu-right'} aria-labelledby="iCartOpenButton">
					<div className="px-2">
						{this.state.products.length == 0 ? (
								<p style={{'textAlign': 'center', margin: 0}}>Your shopping cart is empty!</p>
						) : (
							<div>
								<table className="table table-sm table-striped">
								<tbody>
								{this.state.products.map((product, i) => (
									<tr key={product.cart_id}>
										<td className="text-center"><a href={ product.href }><img src={ product.thumb } alt={ product.name } title={ product.name } className="img-thumbnail"/></a></td>
										<td className="text-start"><a href={ product.href }>{ product.name }</a>
										{product.options.map((option, i) => (
											<div key={i}>
												<small> - { option.name } { option.value }</small>
											</div>
										))}
										</td>
										<td className="text-end">{ product.quantity }</td>
										<td className="text-end">{ product.total }</td>
										<td className="text-end"><button type="submit" className="eRemoveButton" data-bs-toggle="tooltip" data-placement="top" title="Remove product" onClick={(e) => this.removeProduct(product.cart_id)}><i className="fas fa-times"></i></button></td>
									</tr>
								))}
								</tbody>
								</table>
								<table className="table table-sm eTotals">
									<tbody>
									{this.state.totals.map((total, i) => (
										<tr key={i}>
											<td className="text-end"><strong>{ total.title }</strong></td>
											<td className="text-end">{ total.text }</td>
										</tr>
									))}
									</tbody>
								</table>
								<div className="eFullWidth">
									<a href="/all-beds" className="cButton mOrange"><span>Continue Shopping</span></a>
									<a href="/cart" className="cButton mOrange"><span>View Cart</span></a>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}